import * as React from "react";
const SvgNaviCable = (props) => /* @__PURE__ */ React.createElement("svg", { xmlSpace: "preserve", style: {
  enableBackground: "new 0 0 520 54"
}, viewBox: "0 0 520 54", x: "0px", y: "0px", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M481.82,0.56c2.01,0,4.03,0,6.04,0c-0.01,4.03,0,8.06-0.05,12.09c-0.03,2.14-0.3,4.39-1.57,6.05 c-2.61,3.43-6.5,5.1-10.52,6.39c-8.38,2.67-17.1,3.57-25.79,4.19c-9.08,0.64-18.21,0.89-27.32,0.9 c-103.2,0.06-206.4,0.03-309.6,0.04c-19,0-38.04-0.6-56.87,2.63c-4.34,0.75-8.86,1.3-12.52,4.33c-1.12,0.93-1.65,1.84-1.65,3.2 C41.98,43,41.9,45.62,42,48.23c0.08,2.15-0.02,4.18-1.72,5.77c-1,0-2,0-3,0c-0.25-1.23-1.36-2.07-1.31-3.5 c0.12-3.32-0.04-6.65,0.07-9.97c0.07-1.9,0.33-3.94,1.69-5.35c2.67-2.74,6.06-4.36,9.75-5.38c11.11-3.08,22.54-3.6,33.95-4.18 c6.61-0.34,13.25-0.4,19.87-0.41c107.18-0.09,214.36-0.05,321.54-0.09c6.41,0,12.81,0.04,19.23-0.29 c6.89-0.36,13.75-0.88,20.57-1.83c5.29-0.74,10.49-1.82,15.32-4.23c2.57-1.28,4.1-3.02,3.94-6.12 C481.7,8.63,481.83,4.59,481.82,0.56z", style: {
  fill: "#B2B2B2"
} }), /* @__PURE__ */ React.createElement("path", { d: "M40.28,54c1.7-1.59,1.8-3.62,1.72-5.77c-0.1-2.61-0.02-5.23-0.03-7.85c-0.01-1.36,0.53-2.27,1.65-3.2 c3.66-3.03,8.18-3.58,12.52-4.33c18.83-3.23,37.87-2.63,56.87-2.63c103.2-0.01,206.4,0.02,309.6-0.04 c9.11-0.01,18.24-0.25,27.32-0.9c8.7-0.61,17.41-1.51,25.79-4.19c4.02-1.28,7.91-2.95,10.52-6.39c1.27-1.66,1.54-3.92,1.57-6.05 c0.05-4.03,0.04-8.06,0.05-12.09c1.53,0,2.04,0,2.04,1.67c-0.11,3.75,0.08,7.5-0.06,11.24c-0.23,6.08-4.19,9.32-9.34,11.58 c-8.27,3.62-17.1,4.63-25.95,5.47c-15.65,1.49-31.35,1.29-47.04,1.29c-104.12,0-208.24-0.02-312.36,0.03 c-12.91,0.01-25.81,0.53-38.58,2.58c-3.57,0.57-7.11,1.46-10.42,3c-1.38,0.64-2.17,1.72-2.14,3.36c0.06,3.25-0.02,6.51,0.03,9.76 c0.02,1.33-0.26,2.49-1.19,3.46C41.99,54,41.13,54,40.28,54z", style: {
  fill: "#8A8A8A"
} }), /* @__PURE__ */ React.createElement("path", { d: "M481.82,0.56c0.01,4.03-0.12,8.07,0.08,12.09c0.16,3.11-1.37,4.84-3.94,6.12c-4.83,2.41-10.03,3.49-15.32,4.23 c-6.82,0.95-13.68,1.48-20.57,1.83c-6.42,0.33-12.82,0.29-19.23,0.29c-107.18,0.04-214.36,0-321.54,0.09 c-6.62,0.01-13.26,0.07-19.87,0.41c-11.41,0.58-22.85,1.1-33.95,4.18c-3.69,1.02-7.08,2.65-9.75,5.38 c-1.37,1.4-1.63,3.45-1.69,5.35c-0.11,3.32,0.05,6.65-0.07,9.97c-0.05,1.43,1.06,2.27,1.31,3.5c-0.86,0-1.71,0-2.57,0 c-0.63-1.38-0.86-2.82-0.76-4.34c0.25-4.01-0.36-8.02,0.42-12.04c0.69-3.57,3.42-5.39,6.13-6.77c8.45-4.31,17.78-5.24,27.08-6.02 c23.57-1.99,47.21-1.25,70.82-1.26c96.72-0.07,193.43,0,290.15-0.06c12.97-0.01,25.94-0.56,38.73-3c3.47-0.66,6.9-1.65,10.06-3.37 c1.7-0.93,2.47-2.22,2.46-4.09c-0.02-3.54,0.07-7.08-0.04-10.61C479.79,0.82,480.19,0.56,481.82,0.56z", style: {
  fill: "#8A8A8A"
} })));
export default SvgNaviCable;
