import * as React from "react";
const SvgCompositionsThumb = (props) => /* @__PURE__ */ React.createElement("svg", { xmlSpace: "preserve", style: {
  enableBackground: "new 0 0 1000 1000"
}, viewBox: "0 0 1000 1000", x: "0px", y: "0px", ...props }, /* @__PURE__ */ React.createElement("g", { style: {
  fill: "none",
  stroke: "#FFFFFF",
  strokeWidth: 4,
  strokeMiterlimit: 10
} }, /* @__PURE__ */ React.createElement("rect", { height: 840, width: 840, x: 100, y: 60 }), /* @__PURE__ */ React.createElement("rect", { height: 840, width: 840, x: 60, y: 100 })));
export default SvgCompositionsThumb;
